<template>
  <b-navbar v-if="activeCategory" column class="flex-grow-1 pt-0 pb-2">
    <b-navbar-nav class="px-2 d-flex flex-column">
      <div class="category-title-wrapper d-flex align-items-center mx-3">
        <img class="mr-3" :color="activeCategory.color" :src="activeCategory.icon_url" />
        <h2 class="mb-0 font-size-title-small font-weight-normal">{{ activeCategory.label }}</h2>
      </div>
      <b-dropdown-divider class="width-limit color-light-gray" />
      <b-list-group nav class="py-0" :class="{ 'px-0': $mq === 'lg' }">
        <b-list-group-item
          v-for="link in visibleLinks"
          :key="link.label"
          :href="link.url"
          :active="isLinkActive(link)"
          :target="link.external_link ? '_blank' : null"
          class="width-limit mt-2 d-flex align-items-center"
          exact
        >
          <div align-center>
            <span>{{ link.label }}</span>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { isLinkActive } from "./navigation-helper.js";

export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeCategory: null,
    };
  },
  computed: {
    visibleLinks() {
      return this.menuItems
        .find((category) => category.label === this.activeCategory?.label)
        ?.links.filter((l) => l.accessible || l.label === "Livestreams");
    },
  },
  created() {
    this.$bus.$on("activate-nav-category", this.onCategoryActivated);
  },
  destroyed() {
    this.$bus.$off("activate-nav-category", this.onCategoryActivated);
  },
  methods: {
    isLinkActive(link) {
      return isLinkActive(link);
    },
    onCategoryActivated(category) {
      this.activeCategory = category;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  margin-top: var(--top-bar-height);

  &.desktop {
    min-width: 206px;
    max-width: 206px;
  }
}

.navbar-nav {
  width: 100%;
  color: var(--color-tint);
}

.font-size-title-small {
  font-size: 18px;
}

.category-title-wrapper {
  min-height: 60px;
}

.list-group {
  width: 100%;
  overflow-y: auto;
}

.width-limit {
  max-width: 290px;
}

.list-group-item {
  height: 48px;
  border-radius: 56px;
  border: none;
  padding: 1rem;
  color: var(--color-tint);

  &::before {
    display: none;
  }

  &.active {
    font-weight: bold;
    color: inherit;
    background-color: var(--color-ash);
  }

  &:focus-visible,
  &:hover {
    outline: none;
    background-color: var(--color-ash);
  }
}

::v-deep .dropdown-divider {
  margin: 0 !important;
}
</style>
