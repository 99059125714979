<template>
  <b-navbar
    :class="{ 'desktop-with-navigation': $mq === 'lg' && hasSideNavigation }"
    class="py-0"
    app
    color="var(--color-white)"
    flat
    :height="64"
    fixed="top"
  >
    <a
      v-if="$mq === 'lg' && communityUrl"
      class="d-flex align-items-center color-tint text-decoration-none ml-3"
      :href="rootUrl"
    >
      <img class="mr-2" :src="require('./assets/disciple-logo.svg')" height="36" width="36" />
      <h1 class="font-size-title font-weight-bold mb-0">Disciple Console</h1>
    </a>
    <b-btn
      v-else-if="hasSideNavigation"
      class="my-0 p-0 nav-category-toggle"
      @click="$bus.$emit('toggle-mobile-nav-displayed')"
    >
      <div class="img-wrapper d-flex align-items-center justify-content-center">
        <img src="./assets/three-lines.svg" width="20" height="20" />
      </div>
    </b-btn>

    <b-navbar-nav class="mr-4" :class="$mq === 'lg' ? 'ml-auto' : 'flex-grow-1'">
      <a
        class="mx-3 d-flex align-items-center justify-content-end flex-shrink color-tint text-decoration-none"
        :href="communityUrl"
      >
        <img
          v-if="appLogoUrl"
          class="app-logo mr-2"
          :src="appLogoUrl"
          :height="$mq === 'lg' ? 32 : 40"
          :width="$mq === 'lg' ? 32 : 40"
        />
        <img
          v-else-if="!communityUrl"
          class="app-logo mr-2"
          :src="require('./assets/disciple-logo.svg')"
          :height="$mq === 'lg' ? 32 : 40"
          :width="$mq === 'lg' ? 32 : 40"
        />

        <div class="app-name font-size-big font-weight-bold">{{ appName }}</div>
      </a>

      <host-menu v-if="hostMenuLinks" :host-menu-links="hostMenuLinks" />
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import HostMenu from "app_manager/components/navigation/host-menu";

export default {
  components: {
    HostMenu,
  },
  data() {
    return {
      appName: null,
      appLogoUrl: null,
      communityUrl: null,
      hostMenuLinks: null,
      rootUrl: null,
    };
  },
  beforeMount() {
    this.appName = this.$el.attributes["app-name"]?.value;
    this.appLogoUrl = this.$el.attributes["app-logo-url"]?.value;
    this.communityUrl = this.$el.attributes["community-url"]?.value;
    this.rootUrl = this.$el.attributes["root-url"]?.value;
    const navigationData = JSON.parse(this.$el.attributes["navigation-data-string"]?.value);
    this.hostMenuLinks = navigationData.host_menu_items;
    this.hasSideNavigation = navigationData.menu_items?.length > 0;
  },
};
</script>

<style lang="scss" scoped>
.font-size-title {
  font-size: 1.25rem;
}

.navbar {
  height: 64px;
  left: 0 !important;
  margin-left: -1px;
  border-bottom: var(--color-light-gray) solid 1px !important;
  background-color: var(--color-white);
  z-index: 1039;

  .app-logo {
    border-radius: 6px;
  }

  &.desktop-with-navigation {
    left: 88px !important;
  }
}

.navbar-nav {
  justify-content: space-between;
  align-items: center;
}

.nav-category-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 65px !important;
  height: 64px !important;
  border: solid var(--color-light-gray);
  border-width: 0 1px 1px 0;
  border-radius: 0;
  background-color: var(--color-ash) !important;

  &:active {
    box-shadow: none !important;
  }
}

.desktop {
  .nav-category-toggle {
    &:hover {
      .img-wrapper {
        box-shadow:
          0px 0.5px 5px rgba(0, 0, 0, 0.08),
          0px 2px 2px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.img-wrapper {
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
}
</style>
